<template>
  <div class="communal test-list" ref="communal">
    <!-- 顶部固定 -->
    <div class="top-fixed" ref="topFixed">
      <div class="container">
        <el-row :gutter="20" class="p-b-10 font-s14 c66">
          <el-col :span="7">测评日期：</el-col>
          <el-col :span="4">性别：</el-col>
          <el-col :span="4">年级：</el-col>
          <el-col :span="4">填表人：</el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="7">
            <el-date-picker class="el-long" type="daterange" range-separator="-" start-placeholder="起" end-placeholder="终" v-model="search.date" value-format="yyyy-MM-dd" :editable="false">
            </el-date-picker>
          </el-col>
          <el-col :span="4">
            <el-select clearable v-model="search.sex" placeholder="请选择">
              <el-option v-for="item in sexList" :key="item.value" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select clearable v-model="search.grade" placeholder="请选择">
              <el-option v-for="item in sexList" :key="item.value" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select clearable v-model="search.user" placeholder="请选择">
              <el-option v-for="item in userList" :key="item.value" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-col>
          <el-col :span="3">
            <el-button type="success" plain @click="serach">搜索</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="interval"></div>
    </div>
    <!-- 内容 -->
    <div class="content">
      <div class="container" :style="{height:contentHeight+'px'}">
        <el-table ref="multipleTable" :data="list" tooltip-effect="dark" style="width: 100%" :height="contentHeight" :empty-text="tableTip">
          <el-table-column prop="is_self" label="填表人" min-width="120" show-overflow-tooltip />
          <el-table-column prop="grade" label="年级" min-width="120" show-overflow-tooltip />
          <el-table-column prop="sex" label="性别" min-width="120" show-overflow-tooltip />
          <el-table-column prop="addtime" label="提交时间" min-width="120" show-overflow-tooltip />
          <el-table-column prop="cogniton" label="认知维度" min-width="120" show-overflow-tooltip />
          <el-table-column prop="thought" label="思维维度" min-width="120" show-overflow-tooltip />
          <el-table-column prop="personality" label="个性维度" min-width="120" show-overflow-tooltip />
          <el-table-column prop="feeling" label="情绪维度" min-width="120" show-overflow-tooltip />
          <el-table-column prop="will" label="意志行为" min-width="120" show-overflow-tooltip />
          <el-table-column label="操作" fixed="right" width="120">
            <template slot-scope="scope">
              <el-button type="text" @click.stop="operation(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <!-- 分页 100-->
    <div class="paging" ref="paging">
      <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="currentPage" :page-sizes="[10, 50, 100, 2000]" :page-size="psize"
        layout="total, sizes, prev, pager, next, jumper" :total="totals"></el-pagination>
    </div>
    
    <!-- 弹框 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="620px" top="20%">
      <div style="padding:20px">
        <!-- 基本信息 -->
        <div v-if="info.length>0" class="font-s12 clearfix">
          <div v-for="(item,index) in info" :key="index" class="c33 fl mr-30 p-b-10">
            <span class="c99">{{item.title}}</span>
            <span v-if="item.value!=''">{{item.value}}</span>
            <span v-if="item.value==''">-</span>
          </div>
        </div>
        <!-- 表格 -->
        <div class="tab-label">
          <table class="el-long" cellspacing=0>
            <tr class="table-th">
              <template>
                <th v-for="(item,index) in label.column" class="item" :key="index">{{item}}</th>
              </template>
            </tr>
            <tr v-for="(item,index) in label.lines" :key="index">
              <template>
                <td class="item" v-for="(i,idx) in item" :key="idx">{{i.val}}</td>
              </template>
            </tr>
          </table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { ClassScalerList, ClassScalerInfo, GradeList } from "../../api/api";
export default {
  data() {
    return {
      topHeight: "", //顶部搜索的高度
      mainHeight: "", //获取当前容器的高度
      pagingHeight: "", //获取分页的高度
      contentHeight: 0, //内容的高度
      totals: 0,
      pages: "1",
      psize: 10,
      currentPage: 1, //默认显示页
      list: [],
      tableTip: "系统正在检索中…",
      search: {
        date: "",
        sex: "0",
        grade: "不限",
        user: "0",
      },
      sexList: [
        { id: "0", name: "不限" },
        { id: "1", name: "男" },
        { id: "2", name: "女" },
      ],
      gradeList: [],
      userList: [
        { id: "0", name: "不限" },
        { id: "1", name: "本人" },
        { id: "2", name: "父母" },
      ],
      //   弹框
      rowLine: "", //查看保存的行id
      info: [], //基本信息
      label: "", //表格
      dialogVisible: false,
      dialogTitle: "儿童青少年心理健康调查表",
    };
  },
  mounted() {
    var that = this;
    that.common.Count.pageResize(that);
    window.onresize = function temp() {
      that.common.Count.pageResize(that);
    };
  },
  created() {
    this.getList();
    this.common.communalApi.getTags(
      { url: GradeList, name: "gradeList", db: {} },
      this.callBack
    );
  },
  methods: {
    callBack(db, ops) {
      this.$set(this, ops.name, db);
    },
    handleCurrentChange(val) {
      this.pages = val;
      this.getList();
    },
    handleSizeChange(val) {
      this.psize = val;
      this.getList();
    },
    serach() {
      this.pages = "1";
      this.getList();
    },
    getList() {
      var from_date = "";
      var to_date = "";
      if (this.search.date != "" && this.search.date != null) {
        if (this.search.date.length > 0) {
          from_date = this.search.date[0];
          to_date = this.search.date[1];
        }
      }
      var db = {
        from_date: from_date,
        to_date: to_date,
        sex: this.search.sex,
        grade: this.search.grade,
        is_self: this.search.user,
        pages: this.pages,
        pnums: this.psize,
      };
      this.$axios.post(ClassScalerList, db).then(
        (res) => {
          if (res.data.code == 1) {
            if (res.data.data != undefined && res.data.data.length > 0) {
              this.list = res.data.data;
              this.totals = res.data.count;
            } else {
              this.list = [];
              this.totals = res.data.count;
              if (operationName == undefined) {
                this.tableTip = "暂无记录";
              } else {
                this.tableTip = "暂无搜索结果";
              }
            }
          } else {
            this.list = [];
            this.tableTip = "暂无记录";
          }
        },
        (err) => {
          this.list = [];
          this.tableTip = "暂无记录";
        }
      );
    },
    operation(row) {
      //查看
      this.rowLine = row;
      this.$axios
        .post(ClassScalerInfo, { class_record_id: row.class_record_id })
        .then(
          (res) => {
            if (res.data.code == 1) {
              this.info = res.data.data.info;
              this.label = res.data.data.label;
              this.dialogVisible = true;
            } else {
              this.info = [];
              this.label = "";
            }
          },
          (err) => {}
        );
    },
  },
};
</script>

<style lang="scss">
.test-list {
  th {
    font-weight: normal;
    border: 0px;
  }
  table th:last-child {
    border-top-right-radius: 6px;
  }
  table th:first-child {
    border-top-left-radius: 6px;
  }
  .table-th {
    background: #f4f4f4;
    border-radius: 6px;
  }
  td.item,
  th.item {
    text-align: center;
    width: 14%;
    padding: 16px 20px;
  }
  td.item {
    border-bottom: 1px solid #f4f4f4;
  }
  td.item > span {
    color: #333;
  }
  td.item > em {
    font-style: normal;
    color: #ff0000;
    font-size: 12px;
  }
}
</style>
